/* Body */
body {width: 100% !important; margin: 0 !important; padding: 0 !important; line-height: 1.4; word-spacing: 1.1pt; letter-spacing: 0.2pt; font-family: Garamond, Perpetua, "Nimbus Roman No9 L", "Times New Roman", serif; color: #000; background: none; font-size: 11pt;}
/* Strip backgrounds of likely candidates */
header, footer, #branding, #container, #columns, .columns-inner, #content-column, .content-inner, .region, .region-header, .region-content, .article, .article-title, .article-content, .comment, .comment-title, .comment-content, .block, .block-inner, .block-title, .block-content, table, #bottom-banner-content-wrapper, #tertiary-content-wrapper, #footer-wrapper, #header-wrapper {background:none !important;}
/* Hide various parts of the site. */
/*.sidebar,*/#breadcrumb, .nav, .field-type-taxonomy-term-reference, ul.links, ul.links a, .feed-icon, .poll .bar, .poll .bar .foreground, .comment-form, #comment-form, .book-navigation, .tabs, /*#header-wrapper, */#secondary-content-wrapper, #block-print-print-links, #block-block-20, #nav-wrapper {display: none;}
/* Display the main content region */
.region-content {display:block !important;}
/* Layout */
.content-inner {margin: 0 !important; padding: 0 !important;}
/* HTML elements */
h1,h2,h3,h4,h5,h6,#site-name {font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;}
h1 {font-size: 14pt;}
h2 {font-size: 13pt;}
h3 {font-size: 12pt;}
h4,h5,h6 {font-size: 11pt;}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, #site-name a {text-decoration: none !important;}
h1#page-title {margin-top: 1em;}
pre, code, tt, samp, kbd, var {font-size: 10pt; font-family: Consolas, "Lucida Console", Menlo, Monaco, "DejaVu Sans Mono", monospace, sans-serif; /* Safari hack */}
blockquote {margin: 1.3em; padding: 1em; font-size: 10pt; font-style: italic;}
hr {background-color: #ccc;}
/* Header, Logo, Site name */
header {padding-bottom: 15px;}
#logo img {padding: 0 10px 0 0; margin: 0 !important; float:none !important;}
hgroup a:link, hgroup a:visited {text-decoration: none;}
/* Images */
img {float:left; margin: 4px 20px 10px 0; page-break-inside: avoid;}
a img {border:none;}
/* Links */
a:link, a:visited {background: transparent; font-weight: 700; text-decoration: underline; color: #333;}
a:link[href^="http://"]:after, a[href^="http://"]:visited:after {content:" (" attr(href) ") "; font-size: 10pt;}
a[href^="http://"] {color: #000;}
/* Table */
table {margin: 1px; text-align: left;}
th {border-bottom: 1px solid #333; font-weight: 700;}
td {border-bottom: 1px solid #333;}
th,td {padding: 4px 10px 4px 0;}
tfoot {font-style: italic;}
caption {background: #fff; margin-bottom: 1em; text-align: left;}
thead {display: table-header-group;}
tr {page-break-inside: avoid;}
/* Forms */
form {margin-bottom: 10px;}
/* Poll module */
.poll .text, .poll .percent, .poll .total {text-align: left;}
.poll form {margin-bottom: 0;}
/* Articles */
.article {margin-bottom: 10px; border-bottom: 1px solid #ccc;}
/* other customization */
#block-views-related-block h2.block-title, #block-views-related-videos-block-2 h2.block-title, #block-views-related-videos-block-1 h2.block-title, #bottom-banner-content-wrapper h4#moreRR, #tertiary-content-wrapper, #footer-wrapper, #footer-wrapper a {color: #000;}
#header-wrapper .container {background-color: #0071BC;}
.bookdescrip, .bookdescrip p {font-size: 11pt; letter-spacing: 1pt}